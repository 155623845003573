import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";

function Header() {
  const [userDetails, setUserDetails] = useState({});
  useEffect(() => {
    // Check for value in localStorage on component mount
    const storedUsername = JSON.parse(
      localStorage.getItem("__OrientyBlackCarService__")
    );
    if (storedUsername) {
      setUserDetails(storedUsername);
    }
  }, []);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  return (
    <>
      <Modal
        show={showMobileMenu}
        onHide={() => {
          setShowMobileMenu(false);
        }}
        keyboard={false} // Prevents closing on "Escape" key
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>OrientyBlackCarService</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <ul
              style={{
                listStyle: "none",
                margin: 0,
                padding: 0,
              }}
            >
              {!userDetails?.authToken && (
                <>
                  <li
                    style={{
                      marginBottom: 5,
                      paddingBottom: 5,
                    }}
                  >
                    <a
                      className="menu-item"
                      href="/register"
                      style={{
                        width: "100%",
                      }}
                    >
                      Register
                    </a>
                  </li>
                  <li
                    style={{
                      marginBottom: 5,
                      paddingBottom: 5,
                    }}
                  >
                    <a
                      className="menu-item"
                      href="/login"
                      style={{
                        width: "100%",
                      }}
                    >
                      Login
                    </a>
                  </li>
                </>
              )}

              {userDetails?.authToken && (
                <li
                  style={{
                    marginBottom: 5,
                    paddingBottom: 5,
                  }}
                >
                  <a
                    className="menu-item"
                    href="/dashboard"
                    style={{
                      width: "100%",
                    }}
                  >
                    My Account
                  </a>
                </li>
              )}
              <li
                style={{
                  marginBottom: 5,
                  paddingBottom: 5,
                }}
              >
                <a
                  className="menu-item"
                  href="/cars"
                  style={{
                    width: "100%",
                  }}
                >
                  Cars
                </a>
              </li>

              <li
                style={{
                  marginBottom: 5,
                  paddingBottom: 5,
                }}
              >
                <a
                  className="menu-item"
                  href="/about"
                  style={{
                    width: "100%",
                  }}
                >
                  About Us
                </a>
              </li>
              <li
                style={{
                  marginBottom: 5,
                  paddingBottom: 5,
                }}
              >
                <a
                  className="menu-item"
                  href="/contact"
                  style={{
                    width: "100%",
                  }}
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </Modal.Body>
      </Modal>
      <header className="transparent scroll-light has-topbar">
        <div id="topbar" className="topbar-dark text-light">
          <div className="container">
            <div className="topbar-left xs-hide">
              <div className="topbar-widget">
                <div className="topbar-widget">
                  <a href="tel:13478947091">
                    <i className="fa fa-phone"></i>+1(347)894-7091
                  </a>
                </div>
                <div className="topbar-widget">
                  <a href="tel:16803466020">
                    <i className="fa fa-phone"></i>+1(680)346-6020
                  </a>
                </div>
                <div className="topbar-widget">
                  <a href="mailto:contact@orientyblackcarservice.com">
                    <i className="fa fa-envelope"></i>
                    contact@orientyblackcarservice.com
                  </a>
                </div>
              </div>
            </div>

            {/* <div className="topbar-left">
              <div className="social-icons">
                {!userDetails?.authToken ? (
                  <a href="/login">
                    <i
                      className="fa fa-user fa-lg"
                      style={{
                        paddingRight: 0,
                      }}
                    ></i>
                    <span>Sign-In</span>
                  </a>
                ) : (
                  <a href="/dashboard">
                    <i
                      className="fa fa-user fa-lg"
                      style={{
                        paddingRight: 0,
                      }}
                    ></i>
                    <span>My Account</span>
                  </a>
                )}
              </div>
            </div> */}

            <div className="topbar-right">
              <div className="social-icons">
                <a href="https://wa.me/16803466020" target="_blank">
                  <i className="fa fa-whatsapp fa-lg"></i>
                </a>
                <a href="https://wa.me/13478947091" target="_blank">
                  <i className="fa fa-whatsapp fa-lg"></i>
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=61570652796381"
                  target="_blank"
                >
                  <i className="fa fa-facebook fa-lg"></i>
                </a>
                <a
                  href="https://www.instagram.com/orientyblackcar/"
                  target="_blank"
                >
                  <i className="fa fa-instagram fa-lg"></i>
                </a>
                <a
                  href="https://www.tiktok.com/@orientyblackcar"
                  target="_blank"
                >
                  <i className="fa fa-file-video-o fa-lg"></i>
                </a>
                <a
                  href="https://www.linkedin.com/in/orienty-black-car-service-40848a347/"
                  target="_blank"
                >
                  <i className="fa fa-linkedin-square fa-lg"></i>
                </a>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="de-flex sm-pt10">
                <div className="de-flex-col">
                  <div className="de-flex-col">
                    <div id="logo">
                      <a href="/">
                        <img
                          className="logo-1"
                          src="https://res.cloudinary.com/dcfotquxt/image/upload/e_colorize:100,co_white/v1726066341/Orienty%20Black%20Car%20Service/Logos/transparent-file-thumb_gk3aze.png"
                          alt="Book a Chauffeur Ride New York"
                        />
                        <img
                          className="logo-2"
                          src="https://res.cloudinary.com/dcfotquxt/image/upload/v1726066341/Orienty%20Black%20Car%20Service/Logos/transparent-file-thumb_gk3aze.png"
                          alt="Airport Chauffeur Rides"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="de-flex-col header-col-mid">
                  <ul id="mainmenu">
                    {!userDetails?.authToken && (
                      <>
                        <li>
                          <a
                            className="menu-item"
                            href="/register"
                            style={{
                              width: "100%",
                            }}
                          >
                            Register
                          </a>
                        </li>
                        <li>
                          <a
                            className="menu-item"
                            href="/login"
                            style={{
                              width: "100%",
                            }}
                          >
                            Login
                          </a>
                        </li>
                      </>
                    )}

                    {userDetails?.authToken && (
                      <li>
                        <a
                          className="menu-item"
                          href="/dashboard"
                          style={{
                            width: "100%",
                          }}
                        >
                          My Account
                        </a>
                      </li>
                    )}
                    <li>
                      <a
                        className="menu-item"
                        href="/cars"
                        style={{
                          width: "100%",
                        }}
                      >
                        Cars
                      </a>
                    </li>

                    <li>
                      <a
                        className="menu-item"
                        href="/about"
                        style={{
                          width: "100%",
                        }}
                      >
                        About Us
                      </a>
                    </li>
                    <li>
                      <a
                        className="menu-item"
                        href="/contact"
                        style={{
                          width: "100%",
                        }}
                      >
                        Contact
                      </a>
                    </li>
                    <li>
                      <a
                        className="menu-item"
                        href="/terms"
                        style={{
                          width: "100%",
                        }}
                      >
                        Terms & Conditions
                      </a>
                    </li>
                    <li>
                      <a
                        className="menu-item"
                        href="/privacy"
                        style={{
                          width: "100%",
                        }}
                      >
                        Privacy Policy
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="de-flex-col">
                  <div className="menu_side_area">
                    {!userDetails?.authToken && (
                      <a
                        href="/login"
                        className="btn-main"
                        style={{
                          width: "100%",
                        }}
                      >
                        Sign In
                      </a>
                    )}

                    <i
                      className="fa fa-bars fa-lg mobileShow"
                      onClick={() => {
                        setShowMobileMenu(true);
                      }}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
